import * as React from 'react';
import Layout from '../../layouts/Layout';
import JobAlerts from '../../components/JobAlerts/JobAlerts';
// @ts-ignore
import storyHero from '../../images/story-hero.png';
import StorySlides from '../../components/StorySlides/StorySlides';
import ImageHero from '../../components/ImageHero/ImageHero';
import LegacySection from '../../components/LegacySection/LegacySection';
import RoadAheadVideo from '../../components/RoadAheadVideo/RoadAheadVideo';
import GetStartedSection from '../../components/GetStartedSection/GetStartedSection';
import PageIntroSection from '../../components/PageIntroSection/PageIntroSection';

const StoryPage: React.FC = () => {
	const menuItems = [
		{
			text: 'Home',
			href: '/',
			active: false
		},
		{
			text: 'Positions',
			href: '/search/',
			active: false
		},
		{
			text: 'Our Story',
			href: '/story/',
			active: false
		},
		{
			text: 'Military',
			href: '/military/',
			active: false
		},
		{
			text: 'Benefits',
			href: '/benefits/',
			active: false
		},
		{
			text: 'Meet the Team',
			href: '/team/',
			active: false
		},
		{
			text: 'Career Path',
			href: '/career-path/',
			active: false
		}
	];
	return (
		<Layout
			menuItems={menuItems}
			title={'Belle Tire Careers'}
			desc={'Test'}
		>
			<ImageHero
				bgImage={storyHero}
				h1={'Our People Drive Our Company Forward!'}
				h2={
					'We would not have over 100 years of success without our loyal customers and employees, and it’s our talented teams that will get us through the next 100!'
				}
			/>
			<PageIntroSection
				h2={'THE STORY SO FAR'}
				pTags={[
					{
						text: 'Belle Tire was established in 1922 with the belief that customer satisfaction must always be the bottom line. Each day we celebrate the team that got us here and the extraordinary team that will carry us into the next 100 years.'
					},
					{
						text: 'As a family owned company with over 180 retail locations and over 3,000 employees in Michigan, Indiana, Illinois and Ohio, we give our neighbors peace of mind and trust that we will get them back on the road fast and affordably. Belle Tire provides tire and automotive services including shocks and struts, oil changes, brakes, batteries, auto glass and more with an in-store experience that far exceeds a traditional garage.'
					}
				]}
			/>
			<StorySlides />
			{/*<LegacySection />*/}

			<section className={'px-3'}>
				<div
					className={
						'container mx-auto py-7 text-center text-bt-gray'
					}
				>
					<h2 className={'py-5 font-rubik text-4xl font-bold'}>
						WHO WE ARE
					</h2>
					<p className={'my-4 font-rubik text-2xl'}>
						From the beginning, we’ve followed a strong set of
						beliefs, passed down through our actions and
						word-of-mouth alone.
					</p>
					<p className={'my-4 font-rubik text-2xl'}>
						<b>
							See what it means to be part of the Belle Tire
							family!
						</b>
					</p>
					<div className={'mx-auto flex self-center py-7'}>
						<iframe
							className={'mx-auto aspect-video w-full max-w-5xl'}
							src="https://www.youtube.com/embed/Fc8khs-SiZY"
							title="YouTube video player"
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							allowFullScreen
						/>
					</div>
				</div>
			</section>
			<JobAlerts />
			<GetStartedSection />
		</Layout>
	);
};

export default StoryPage;
